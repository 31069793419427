<template>
    <div>
        <section class="gutter-bottom">
            <div class="container">
                <div class="our-mission-wrapper">
                    <div class="text-column">
                        <h1 class="title">
                            Our Mission
                        </h1>
                        <p>
                            Build an application with a single user interface that has a central marketplace of applications and operation systems that can be provisioned in to any cloud, public or private anywhere in the world.
                        </p>
                        <p>
                            Why did we think this was a good idea?
                        </p>
                        <p>
                            Over the last 20 years we have seen the commoditisation of the hardware layer in many appliance vendors products, so more and more of the IP was in software, and hardware was delivered by an OEM/ODM partner.
                        </p>
                        <p>
                            The meteoric rise of the hyperscale public cloud vendors means now the hardware is in situ, and delivered as a service (IaaS), therefore we need a reliable and repeatable method to provision these applications and operating systems to the hardware.
                        </p>
                        <p>
                            ...welcome illapa!
                        </p>
                    </div>
                    <div class="image-column">
                        <div class="image">
                            <img src="/images/jason-dance-ceo.webp" alt="Jason Dance CEO">
                        </div>
                        <div class="image-tag">
                            <a href="https://www.linkedin.com/in/jasondance/" target="_blank" aria-label="Jason Dance LinkedIn">
                                <i class="bi bi-linkedin linkedin-icon"></i>
                            </a>
                            Jason Dance
                            <span class="text-blue"> Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'About Us | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'Build an application with a single user interface that has a central marketplace of applications and operation systems that can be provisioned in to any cloud, public or private anywhere in the world.'
                },
                {
                    property: 'og:title',
                    content: 'About Us | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'Build an application with a single user interface that has a central marketplace of applications and operation systems that can be provisioned in to any cloud, public or private anywhere in the world.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/company/about-us'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/company/about-us'
                }
            ]
        }
    },
}
</script>

<style scoped>
.our-mission-wrapper {
    display: flex;
    text-align: left;
    padding-top: 160px;
}
.our-mission-wrapper .text-column .title {
    font-size: 40px;
    font-weight: 300;
    position: relative;
}
.our-mission-wrapper .text-column .title::before {
    content: "ABOUT US";
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 5px;
    color: var(--brand-accent-blue);
}
.our-mission-wrapper .text-column p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.7;
    color: var(--text-dark-secondary);
}
.our-mission-wrapper .image-column .image {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin-bottom: 1rem;
}
.our-mission-wrapper .image-column .image img {
    width: 100%;
    object-fit: contain;
    border-radius: 12px;
}
.our-mission-wrapper .image-column .image::before {
    content: "";
    width: 250px;
    height: 250px;
    background-image: url("../assets/mission-image-dots.svg");
    position: absolute;
    top: -60px;
    left: -60px;
}
.our-mission-wrapper .image-column .image::after {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("../assets/mission-image-dots.svg");
    position: absolute;
    bottom: 0;
    right: -40px;
}
.our-mission-wrapper .image-column .image-tag {
    font-size: 20px;
    color: var(--text-dark-secondary);
}
.our-mission-wrapper .image-column .image-tag .linkedin-icon {
    margin-right: 10px;
    color: var(--brand-accent-blue);
}
@media only screen and (max-width: 1024px) {
    .our-mission-wrapper {
        flex-direction: column;
        padding-top: 80px;
    }
    .our-mission-wrapper .text-column,
    .our-mission-wrapper .image-column {
        width: 100%;
        flex: 0 0 100%;
    }
    .our-mission-wrapper .image-column {
        margin-top: 100px;
    }
}
@media only screen and (min-width: 1024px) {
    .our-mission-wrapper .text-column p {
        max-width: 550px;
        margin-right: 20px;
    }
    .our-mission-wrapper .text-column,
    .our-mission-wrapper .image-column {
        width: 50%;
        flex: 0 0 50%;
    }
}
</style>